
<script lang="ts">
    export interface IEmits {
        (e: "save"): void;
        (e: "cancel"): void;
    };
</script>

<script setup lang="ts">
    import { ref, watch, computed } from "vue";
    import { type DataItemModel } from "o365-dataobject";
    import { isMobile } from "o365.GlobalState.ts";

    import OActionSave from "./Actions.Save.vue";
    import OActionCancel from "./Actions.Cancel.vue";

    export interface IProps {
        row: DataItemModel;
        grow?: boolean;
        variant?: "btn" | "link";
        justify?: "start" | "center" | "end";
        field?: string;
        fields?: string[];
        autoShow?: boolean;
        autoHide?: boolean;
    };

    const props = withDefaults(defineProps<IProps>(), { variant: "btn", autoShow: true, autoHide: true });
    const emit = defineEmits<IEmits>();
    const model = defineModel<any>(); // if boolean, vue converts null to false, which is bad in this case

    const hasModelValue = model.value !== undefined; // we want initial value, so this cannot be computed

    const fields = computed<string[]>(() => {
        let list: string[] = [];
        if (props.field) {
            list.push(props.field);
        }
        if (props.fields) {
            list = list.concat(props.fields);
        }
        return list;
    });
    const animate = computed(() => hasModelValue || fields.value.length > 0);
    const justify = computed(() => props.justify ?? (isMobile.value ? "end" : "start"));

    const saveClass = computed<string>(() => {
        const classes: Array<string> = [];
        if (props.grow) {
            classes.push("flex-grow-1");
        }
        if (props.variant === "btn") {
            classes.push("btn", "btn-primary");
        } else if (props.variant === "link") {
            classes.push("btn", "btn-link");
        }
        return classes.join(" ");
    });

    const cancelClass = computed<string>(() => {
        const classes: Array<string> = [];
        if (props.grow) {
            classes.push("flex-grow-1");
        }
        if (props.variant === "btn") {
            classes.push("btn", "btn-outline-primary");
        } else if (props.variant === "link") {
            classes.push("btn", "btn-link");
        }
        return classes.join(" ");
    });

    const hasChanges = computed<boolean>(() => {
        if (fields.value.length) {
            // check only fields if fields are provided
            const fieldsChanged = fields.value.some(field => props.row?.changes?.[field] !== undefined);
            const propsChanged = fields.value.some(field => props.row?.propertiesRows?.[field]?.hasChanges);
            return fieldsChanged || propsChanged;
        } else {
            // check any changes if no fields are provided
            return props.row.hasChanges;
        }
    });

    const anim = computed(() => {
        if (animate.value) {
            return "slide-down";
        };
        return null;
    });

    watch(hasChanges, () => {
        if (hasChanges.value && props.autoShow !== false) {
            model.value = true;
        }
    });

    watch(() => [ hasChanges.value, props.row.isSaving ], ([ hasChanges, isSaving ]) => {
        if (!hasChanges && !isSaving && props.autoHide !== false) {
            model.value = false;
        }
    });

    // always close after save
    watch(() => props.row.isSaving, (isSaving) => {
        if (!isSaving) {
            model.value = false;
        }
    });
</script>

<template>
    <transition :name="anim ?? ''" :css="!!anim">
        <template v-if="model || !animate">
            <div class="d-flex gap-2" :style="{ 'justify-content': justify }" v-bind="$attrs">
                <OActionCancel :class="cancelClass" :row="row" enabledWithoutChanges @canceled="model = false">
                    {{ $t("Cancel") }}
                </OActionCancel>
                <OActionSave :class="saveClass" :row="row">
                    {{ $t("Save") }}
                </OActionSave>
            </div>
        </template>
    </transition>
</template>

<style scoped>
    .slide-down-enter-from, .slide-down-leave-to {
        max-height: 0px;
        transform: translate(0px, -40px);
        opacity: 0%;
    }
    .slide-down-enter-active, .slide-down-leave-active {
        transition: all 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
    }
    .slide-down-enter-to, .slide-down-leave-from {
        max-height: 100px;
        transform: translate(0px, 0px);
        opacity: 100%;
    }
</style>
